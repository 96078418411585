body {
  margin: 0;
  font-family: 'Inter', 'StabilGrotesk', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

div#root {
  height: 100%;
}

@font-face {
  font-family: 'StabilGrotesk';
  src: url('shared/assets/fonts/StabilGrotesk/StabilGrotesk-Regular.otf')
    format('opentype');
}

@font-face {
  font-family: 'StabilGrotesk';
  font-weight: bold;
  src: url('shared/assets/fonts/StabilGrotesk/StabilGrotesk-Medium.otf')
    format('opentype');
}

@font-face {
  font-family: 'StabilGrotesk';
  font-weight: lighter;
  src: url('shared/assets/fonts/StabilGrotesk/StabilGrotesk-Light.otf')
    format('opentype');
}

@font-face {
  font-family: 'Inter';
  src: url('shared/assets/fonts/Inter/Inter-Regular.ttf') format('opentype');
}

@font-face {
  font-family: 'Inter';
  font-weight: bold;
  src: url('shared/assets/fonts/Inter/Inter-Bold.ttf') format('opentype');
}

@font-face {
  font-family: 'Inter';
  font-weight: lighter;
  src: url('shared/assets/fonts/Inter/Inter-Medium.ttf') format('opentype');
}
